
import { defineComponent, onMounted } from "vue";
import Doctors from "@/components/common/Doctors/Doctors.vue";
import { PossibleTypes } from "@/models/doctor";
import { useStore } from "vuex";
import { CommonMutationTypes } from "@/store/modules/common/mutations";

export default defineComponent({
  name: "My Doctor",
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit(CommonMutationTypes.SET_IS_LODING, true);
    });

    return { PossibleTypes };
  },
  components: {
    Doctors,
  },
});
